import { Injectable } from '@angular/core';
const TOKEN_KEY = 'auth-token';
@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {

  private ls = window.localStorage;
  constructor() { }

  public setItem(key, value) {
    value = JSON.stringify(value);
    this.ls.setItem(key, value);
    return true;
  }

    public saveToken(token: string): void {
   this.ls.clear();
    window.sessionStorage.setItem(TOKEN_KEY,token);
  }

  public getItem(key) {
    const value = this.ls.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      // console.log(e)
      return null;
    }
  }
  public clear() {
    this.ls.clear();
  }
}
