import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { UserProfileComponent } from './views/pages/user-profile/user-profile.component';

const adminRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule)
  },

  {
    path: 'user',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'lighting-view',
    loadChildren: () => import('./views/lighting/lighting.module').then(m => m.LightingModule)
  },
  {
    path: 'air',
    loadChildren: () => import('./views/air/air.module').then(m => m.AirModule)
  },
  {
    path: 'generator',
    loadChildren: () => import('./views/generator/generator.module').then(m => m.GeneratorModule)
  },
  {
    path: 'firepump',
    loadChildren: () => import('./views/firepump/firepump.module').then(m => m.FirepumpModule)
  },
  {
    path: 'poolsystem',
    loadChildren: () => import('./views/pool-system/pool-system.module').then(m => m.PoolSystemModule)
  },
  {
    path: 'wastwater',
    loadChildren: () => import('./views/wastwater/wastwater.module').then(m => m.WastwaterModule)
  },
  {
    path: 'sanitary',
    loadChildren: () => import('./views/sanitary/sanitary.module').then(m => m.SanitaryModule)
  },
  {
    path: 'energy',
    loadChildren: () => import('./views/energy/energy.module').then(m => m.EnergyModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./views/report/report.module').then(m => m.ReportModule)
  },

   {
    path: 'schedule-manager',
    loadChildren: () => import('./views/schedules/schedules.module').then(m => m.SchedulesModule)
  },







];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'energy',
    pathMatch: 'full'
  },

  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
