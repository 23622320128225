import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CredentialInterceptor } from "./interceptors/credential.interceptor";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ScheduleScensListComponent } from "./views/schedules/schedule-scens-list/schedule-scens-list.component";
import { ScheduleListComponent } from "./views/schedules/schedule-list/schedule-list.component";
import { CalendarModule } from "primeng/calendar";

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, SharedModule, HttpClientModule, BrowserAnimationsModule, AppRoutingModule, NgMultiSelectDropDownModule.forRoot(),CalendarModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
