import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Auth } from 'src/app/classes/auth';
import { User } from 'src/app/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {
  user: User
  isLoggedIn: any
  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.auth.user().subscribe(
      user => {
        Auth.userEmitter.emit(user);
        Auth.user = user;
        this.user = user;
      },
      () => {
        this.router.navigate(['/sessions/signin'], { queryParams: { returnUrl: state.url.toString() }});
        console.log(state.url.toString())
       /*  return false; */
      }
    );
    return true;

  }
}
